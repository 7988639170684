import axios from "axios";
import {
    makeObservable,
    observable,
    computed,
    action,
    flow,
    makeAutoObservable,
} from "mobx";

import { Text } from "preact-i18n";
import { useEffect, useState } from "preact/hooks";

import { ModalForm } from "@revoltchat/ui";

import { useClient } from "../../client/ClientController";
import { mapError } from "../../client/jsx/error";
import { ModalProps } from "../types";

let type = "";

/**
 * Bot creation modal
 */
export default function CreateAgent({
    onCreate,
    ...props
}: ModalProps<"create_agent">) {
    const client = useClient();
    const [modelData, setModelData] = useState({
        displayName: "",
        type: "",
        llmModelName: "",
        children: [{ name: "请先选择模型", value: "__" }],
    });

    useEffect(() => {
        console.log(modelData, "- Has changed");
    }, [modelData]);

    return (
        <ModalForm
            {...props}
            title={<Text id="app.special.popovers.create_agent.title" />}
            schema={{
                displayName: "text",
                model: "radio",
                modelName: "radio",
                systemPrompt: "text",
            }}
            data={{
                displayName: {
                    field: (
                        <Text id="app.special.popovers.create_agent.displayName" />
                    ) as React.ReactChild,
                },
                model: {
                    field: (
                        <Text id="app.special.popovers.create_agent.type" />
                    ) as React.ReactChild,
                    choices: props.modelTypes,
                    // choices: [
                    //     {
                    //         title: "单轮",
                    //         value: "text-single",
                    //         onSelect: () => {
                    //             const v = "text-single";
                    //             setModelData({
                    //                 ...modelData,
                    //                 children: props.modelTypes.filter(
                    //                     (x) => x.value === v,
                    //                 )[0].children,
                    //                 type: v,
                    //             });
                    //         },
                    //     },
                    // ],
                    value: modelData.type,
                    onChange: (v) => {
                        setModelData({
                            children: props.modelTypes.filter(
                                (x) => x.value === v,
                            )[0].children,
                            type: v,
                        });
                        type = v;
                    },
                },
                modelName: {
                    field: (
                        <Text id="app.special.popovers.create_agent.modelName" />
                    ) as React.ReactChild,
                    choices: modelData.children,
                },
                systemPrompt: {
                    field: (
                        <Text id="app.special.popovers.create_agent.systemPrompt" />
                    ) as React.ReactChild,
                },
            }}
            callback={async (data) => {
                const payload = {
                    display_name: data.displayName,
                    llm_model_name: data.modelName,
                    system_prompt: data.systemPrompt,
                    type: type,
                };
                axios
                    .create(client.api.config)
                    .post("/agents/api/agent/store", payload)
                    .then(({ data }) => onCreate(data.data));
            }}
            submit={{
                children: <Text id="app.special.modals.actions.create" />,
            }}
        />
    );
}

